import {
  format,
  lightFormat,
  parseISO,
  setYear,
  setWeek,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  getWeek,
  getYear,
  addDays,
  addWeeks,
  isSameYear,
  isSameMonth,
} from 'date-fns';

export function dateIndex(date: Date) {
  return lightFormat(date, 'yyyy-MM-dd');
}

export function weekFormat(date: string) {
  return format(parseISO(date), 'dd EEE');
}

export function dateFormat(date: string | Date) {
  return format(typeof date === 'string' ? parseISO(date) : date, 'd MMMM, Y');
}

export function dateRangeFormat(begin: string, end: string) {
  const beginDate = parseISO(begin);
  const endDate = parseISO(end);

  const endStr = format(endDate, 'd MMMM, Y');

  if (isSameYear(beginDate, endDate)) {
    if (isSameMonth(beginDate, endDate)) {
      const beginStr = format(beginDate, 'd');
      return `${beginStr} – ${endStr}`;
    }

    const beginStr = format(beginDate, 'd MMMM');
    return `${beginStr} – ${endStr}`;
  }

  const beginStr = format(beginDate, 'd MMMM, Y');
  return `${beginStr} — ${endStr}`;
}

export function getCurrentYear() {
  return getYear(new Date());
}

const weekOptions = {
  weekStartsOn: 1 as const,
};

export function getCurrentWeekNumber() {
  return getWeek(new Date(), weekOptions);
}

export function daysOfWeek(weekNumber: number, year: number) {
  let date: Date;
  date = new Date();
  date = setYear(date, year);
  date = setWeek(date, weekNumber, weekOptions);

  return eachDayOfInterval({
    start: startOfWeek(date, weekOptions),
    end: endOfWeek(date, weekOptions),
  });
}

export function nextSevenDays(startDate: string) {
  const start = parseISO(startDate);

  return eachDayOfInterval({
    start,
    end: addDays(start, 6),
  });
}

export function getNextDay(date: DateIndex) {
  return addDays(parseISO(date), 1);
}

export function getPrevDay(date: string) {
  return addDays(parseISO(date), -1);
}

export function startOfNextWeek(date: Date) {
  return dateIndex(startOfWeek(addWeeks(date, 1), weekOptions));
}

export function endOfNextWeek(date: Date) {
  return dateIndex(endOfWeek(addWeeks(date, 1), weekOptions));
}

export function getLastFourWeeks(date: Date) {
  const fourWeeksAgo = addWeeks(date, -3);
  const startOfThat = startOfWeek(fourWeeksAgo, weekOptions);
  const endOfThisWeek = endOfWeek(date, weekOptions);

  return eachDayOfInterval({
    start: startOfThat,
    end: endOfThisWeek,
  });
}
