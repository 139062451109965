import { createReducer } from 'typesafe-actions';
import { dateIndex, getNextDay, getPrevDay } from '../../helpers/dates';
import {
  OverviewAction,
  incrementDate,
  decrementDate,
  changeDate,
  resetDate,
} from './overview-actions';

interface State {
  currentDate: DateIndex;
}

const initialState: State = {
  currentDate: dateIndex(new Date()),
};

const overviewReducer = createReducer<State, OverviewAction>(initialState)
  .handleAction(changeDate, (state, { payload }) => {
    return { ...state, currentDate: payload };
  })
  .handleAction(incrementDate, state => {
    const nextDay = dateIndex(getNextDay(state.currentDate));
    return { ...state, currentDate: nextDay };
  })
  .handleAction(decrementDate, state => {
    const prevDay = dateIndex(getPrevDay(state.currentDate));
    return { ...state, currentDate: prevDay };
  })
  .handleAction(resetDate, state => {
    return { ...state, currentDate: dateIndex(new Date()) };
  });

export default overviewReducer;
