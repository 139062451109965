import React from 'react';
import styled from 'styled-components/macro';
import cx from 'classnames';

import closeIcon from '../icons/close.svg';
import overviewIcon from '../icons/overview.svg';
import activitiesIcon from '../icons/activities.svg';
import useLockBodyScroll from '../hooks/use-lock-body-scroll';
import { A } from 'hookrouter';
import { fromColons } from '../helpers/emojis';

const MenuCss = styled.div<{ shown: boolean }>`
  transition: transform 0.5s ease-in-out;
  transform: translateX(${props => (props.shown ? '0%' : '-110%')});
`;

const BackgroundCss = styled.div<{ shown: boolean }>`
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.shown ? 0.5 : 0)};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

function MobileMenu({ shown, toggle, onNavigate }: any) {
  const ref = useLockBodyScroll(shown);

  const backgroundNames = cx(
    'fixed z-10 inset-0 bg-gray-500 w-screen h-screen',
    shown ? '' : 'pointer-events-none',
  );

  return (
    <div ref={ref}>
      <MenuCss
        className="fixed inset-0 h-screen z-20 bg-gray-900 w-64 md:w-full md:max-w-sm"
        shown={shown}
      >
        <div className="p-8 bg-gray-900 text-white">
          <div className="text-3xl font-thin">Mivida</div>
        </div>
        <div className="p-8">
          <A
            className="flex items-center text-lg mb-4 text-xl"
            href="/"
            onClick={onNavigate}
          >
            Overview
          </A>
          <A
            className="flex items-center text-lg mb-4 text-xl"
            href="/activities"
            onClick={onNavigate}
          >
            Activities
          </A>
          <A
            className="flex items-center text-lg mb-4 text-xl"
            href="/calendar"
            onClick={onNavigate}
          >
            Calendar
          </A>
        </div>
      </MenuCss>
      <BackgroundCss
        className={backgroundNames}
        onClick={toggle}
        shown={shown}
      />
    </div>
  );
}

export default MobileMenu;
