import { dateIndex } from '../helpers/dates';
import { addDays } from '@fullcalendar/core';
import { Database } from './database/database';

const today = new Date();

const showers = [
  dateIndex(addDays(today, -4)),
  dateIndex(addDays(today, -2)),
  dateIndex(addDays(today, -1)),
  dateIndex(today),
];

const medications = [
  dateIndex(addDays(today, -3)),
  dateIndex(addDays(today, -2)),
  dateIndex(today),
];

const meditation = [
  dateIndex(addDays(today, -3)),
  dateIndex(addDays(today, -2)),
];

export async function seed(db: Database) {
  const showerActivity = await db.activities.insert({
    _id: 'shower',
    archived: true,
    type: 'activity',
    title: 'Shower',
    shortcode: ':shower:',
    temporary: false,
    hidden: false,
  });

  showers.forEach(seedActivity(db, showerActivity._id));

  const medicationActivity = await db.activities.insert({
    _id: 'medication',
    archived: false,
    type: 'activity',
    title: 'Medication',
    shortcode: ':pill:',
    temporary: false,
    hidden: true,
  });

  medications.forEach(seedActivity(db, medicationActivity._id));

  const meditationActivity = await db.activities.insert({
    _id: 'meditation',
    archived: false,
    type: 'activity',
    title: 'Meditation',
    shortcode: ':relieved:',
    temporary: false,
    hidden: false,
  });

  meditation.forEach(seedActivity(db, meditationActivity._id));
}

function seedActivity(db: Database, id: string) {
  return (date: string) => {
    db.day_activities.insert({
      date,
      type: 'day_activity',
      activityId: id,
    });
  };
}
