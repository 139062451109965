import RxDB, { RxDatabase } from 'rxdb';
import Cookie from 'js-cookie';
import uuid from 'uuid/v4';
import MemoryAdapter from 'pouchdb-adapter-memory';
import HttpAdapter from 'pouchdb-adapter-http';
import IndexedDBAdapter from 'pouchdb-adapter-idb';
import Authentication from 'pouchdb-authentication';
import {
  ActivityCollection,
  activitySchema,
  ActivityDocument,
  activityMigrationStrategies,
} from './entities/activity';
import {
  DayActivityCollection,
  dayActivitySchema,
  DayActivityDocument,
} from './entities/day-activity';
import { isProduction } from '../../env';
import urls from '../../urls';
import { seed } from '../seed';

type DatabaseDocumentTypes =
  | ActivityDocument['type']
  | DayActivityDocument['type'];

export type DatabaseCollections = {
  activities: ActivityCollection;
  day_activities: DayActivityCollection;
};

export type Database = RxDatabase<DatabaseCollections>;

RxDB.plugin(Authentication);
RxDB.plugin(HttpAdapter);

if (isProduction) {
  RxDB.plugin(IndexedDBAdapter);
} else {
  RxDB.plugin(MemoryAdapter);
}

const validRe = /[^a-z_$0-9]/gi;
const userName = Cookie.get('name');
const name = userName
  ? `${userName.toLowerCase().replace(validRe, '_')}_mivida`
  : 'mivida';

async function createDatabase() {
  const db = await RxDB.create<DatabaseCollections>({
    name,
    adapter: isProduction ? 'idb' : 'memory',
  });

  await db.collection({
    name: 'activities',
    schema: activitySchema,
    migrationStrategies: activityMigrationStrategies,
  });
  await db.collection({ name: 'day_activities', schema: dayActivitySchema });

  if (!isProduction) {
    await seed(db);
  }

  return db;
}

export const database = createDatabase();

window.database = database;
window.seed = seed;

export async function setupSubscription() {
  if (!Cookie.get('token') || !Cookie.get('name')) {
    console.log("Don't have the required info to sync");
    return;
  }

  const db = await database;
  console.log('Starting sync');

  replicate(db.activities, 'activity');
  replicate(db.day_activities, 'day_activity');
}

function replicate<K extends keyof DatabaseCollections>(
  collection: DatabaseCollections[K],
  type: DatabaseDocumentTypes,
) {
  const { server } = urls;

  collection.sync({
    remote: `${server}/db`,
    query: collection
      .find()
      .where('type')
      .eq(type),
  });
}

setupSubscription();
