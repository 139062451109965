import { createReducer } from 'typesafe-actions';
import {
  ActivityDocument,
  ActivityId,
} from '../../data/database/entities/activity';
import {
  receiveActivities,
  EntitiesAction,
  receiveDayActivities,
} from './entities-actions';
import { DayActivityDocument } from '../../data/database/entities/day-activity';

interface State {
  activities: {
    byId?: Record<string, ActivityDocument>;
  };
  dayActivities: {
    byActivity: Record<ActivityId, DateIndex[] | undefined>;
    byDate: Record<DateIndex, ActivityId[] | undefined>;
  };
}

const initialState: State = {
  activities: {},
  dayActivities: { byActivity: {}, byDate: {} },
};

const entitiesReducer = createReducer<State, EntitiesAction>(initialState)
  .handleAction(receiveActivities, (state, { payload }) => {
    const accumulator = {} as State['activities']['byId'];
    const byId = payload.reduce((acc, activity) => {
      return { ...acc, [activity._id]: activity };
    }, accumulator);

    return { ...state, activities: { byId } };
  })

  .handleAction(receiveDayActivities, (state, { payload }) => {
    const idAccumulator = {} as State['dayActivities']['byActivity'];
    const byActivity = payload.reduce((acc, { date, activityId }) => {
      const current = acc[activityId] || [];
      return { ...acc, [activityId]: [...current, date] };
    }, idAccumulator);

    const dateAccumulator = {} as State['dayActivities']['byDate'];
    const byDate = payload.reduce((acc, { date, activityId }) => {
      const current = acc[date] || [];
      return { ...acc, [date]: [...current, activityId] };
    }, dateAccumulator);

    return { ...state, dayActivities: { byActivity, byDate } };
  });

export default entitiesReducer;
