import { getActivity, receiveActivity, notFoundActivity } from './actions';
import { Observable, from, combineLatest, merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  combineEpics,
  StateObservable,
  ActionsObservable,
} from 'redux-observable';
import { database } from '../data/database/database';
import {
  changeDate,
  decrementDate,
  incrementDate,
} from './overview/overview-actions';
import {
  receiveActivities,
  receiveDayActivities,
} from '../store/entities/entities-actions';
import { init } from './app/app-actions';

function activitiesEpic(
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>,
) {
  return action$.pipe(filter(isActionOf(init))).pipe(
    mergeMap(() => {
      const { database } = state$.value.app;

      return database.activities.find().$;
    }),
    map(receiveActivities),
  );
}

function dayActivitiesEpic(
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>,
) {
  return merge(
    action$.pipe(filter(isActionOf(init))),
    // action$.pipe(filter(isActionOf(changeDate))),
    // action$.pipe(filter(isActionOf(incrementDate))),
    // action$.pipe(filter(isActionOf(decrementDate))),
  ).pipe(
    mergeMap(() => {
      // const { currentDate } = state$.value.overview;
      const { database } = state$.value.app;

      return database.day_activities.find().$;
    }),
    map(receiveDayActivities),
  );
}

export const rootEpic = combineEpics(activitiesEpic, dayActivitiesEpic);
