import { createReducer } from 'typesafe-actions';
import { endOfWeek, addDays } from 'date-fns';
import { CalendarAction, calendarActions } from './calendar-actions';
import produce from 'immer';

interface State {
  relativeDate: Date;
  activityFilter: string[];
}

const initialState: State = {
  relativeDate: endOfWeek(new Date()),
  activityFilter: [],
};

const calendarReducer = createReducer<State, CalendarAction>(initialState)
  .handleAction(calendarActions.changeDate, (state, { payload }) => {
    return produce(state, draft => {
      if (payload === 0) {
        draft.relativeDate = endOfWeek(new Date());
      } else {
        draft.relativeDate = addDays(draft.relativeDate, payload);
      }
    });
  })
  .handleAction(calendarActions.toggleActivity, (state, { payload }) => {
    return produce(state, draft => {
      if (draft.activityFilter.includes(payload)) {
        draft.activityFilter = draft.activityFilter.filter(
          id => payload !== id,
        );
      } else {
        draft.activityFilter.push(payload);
      }
    });
  });

export default calendarReducer;
