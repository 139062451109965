import { createAction, ActionType } from 'typesafe-actions';
import { Database } from '../../data/database/database';

export const init = createAction('APP_INITIALIZED', action => {
  return (db: Database) => action({ db });
});

const actions = { init };

export type AppAction = ActionType<typeof actions>;
