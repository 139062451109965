import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import './index.build.css';
import { isProduction } from './env';
import { database } from './data/database/database';
import { init } from './store/app/app-actions';

database.then(db => {
  store.dispatch(init(db));

  ReactDOM.render(<App store={store} />, document.getElementById('root'));
});

function handleSuccess() {
  const message = 'The app is now available for offline use.';
  toast(message);
}

function handleUpdate() {
  const message = 'An update is available. Click here to install.';

  toast(message, {
    autoClose: false,
    onClick: () => {
      window.location.reload();
    },
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (isProduction) {
  serviceWorker.register({
    onSuccess: handleSuccess,
    onUpdate: handleUpdate,
  });
} else {
  serviceWorker.unregister();
}
