import { isProduction } from './env';

type ProjectUrls = {
  server: string;
};

const develop: ProjectUrls = {
  server: 'http://localhost:3030',
};

const production: ProjectUrls = {
  server: 'https://api.lauritz.me',
};

const urls = isProduction ? production : develop;

export default urls;
