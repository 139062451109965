import { createAction, ActionType } from 'typesafe-actions';

export const calendarActions = {
  changeDate: createAction('calendar/RELATIVE_DATE_CHANGED', action => {
    return (amount: -30 | -7 | 0 | 7 | 30) => action(amount);
  }),

  toggleActivity: createAction('calendar/ACTIVITY_TOGGLED', action => {
    return (activityId: string) => action(activityId);
  }),
};

export type CalendarAction = ActionType<typeof calendarActions>;
