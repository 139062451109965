import { AppAction, init } from './app-actions';
import { createReducer } from 'typesafe-actions';
import { Database } from '../../data/database/database';

interface State {
  database: Database;
}

const initialState: State = {
  // Database is replaced before App is being rendered
  database: undefined as any,
};

const appReducer = createReducer<State, AppAction>(initialState).handleAction(
  init,
  (state, { payload }) => {
    return { ...state, database: payload.db };
  },
);

export default appReducer;
