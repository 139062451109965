import { createAction, ActionType } from 'typesafe-actions';
import { ActivityId } from '../../data/database/entities/activity';

export const changeDate = createAction('DATE_CHANGED', action => {
  return (date: DateIndex) => action(date);
});

export const incrementDate = createAction('DATE_INCREMENTED', action => {
  return () => action();
});

export const decrementDate = createAction('DATE_DECREMENTED', action => {
  return () => action();
});

export const toggleActivity = createAction('ACTIVITY_TOGGLED', action => {
  return (id: ActivityId) => action(id);
});

export const resetDate = createAction('DATE_RESET', action => {
  return () => action();
});

const actions = {
  changeDate,
  incrementDate,
  decrementDate,
  toggleActivity,
  resetDate,
};

export type OverviewAction = ActionType<typeof actions>;
