import { A, navigate } from 'hookrouter';
import React, { Suspense, useState } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import Cookie from 'js-cookie';

import logoIcon from '../icons/logo.svg';
import menuIcon from '../icons/menu.svg';
import accountIcon from '../icons/account.svg';
import searchIcon from '../icons/search.svg';

import MobileMenu from '../components/MobileMenu';
import { isProduction } from '../env';

type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  padding?: boolean;
};

function Layout({ children, padding = true, ...props }: Props) {
  const [menuShown, setMenuShown] = useState(false);

  const loggedIn = Cookie.get('token');

  return (
    <div {...props}>
      <div className="relative flex items-center px-4 h-20 border-b border-gray-800">
        <A href="/">
          <LogoCss src={logoIcon} className="absolute" />
        </A>
        <img
          className="cursor-pointer"
          src={menuIcon}
          onClick={() => setMenuShown(true)}
        />
        <div className="flex-1" />
        {isProduction ? null : <img src={searchIcon} className="mr-4" />}
        <img
          src={accountIcon}
          className={loggedIn ? 'opacity-100' : 'opacity-50'}
          onClick={() => {
            if (loggedIn) {
              navigate('/user');
            } else {
              navigate('/login');
            }
          }}
        />
      </div>

      <MobileMenu
        shown={menuShown}
        toggle={() => setMenuShown(false)}
        onNavigate={() => setMenuShown(false)}
      />

      <Suspense fallback={null}>
        <MainCss className={padding ? 'mt-8 m-4' : ''}>{children}</MainCss>
      </Suspense>
    </div>
  );
}

export default Layout;

const MainCss = styled.main`
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  grid-area: main;
`;

const LogoCss = styled.img`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
