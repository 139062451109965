import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import entitiesReducer from './entities/entities-reducer';
import calendarReducer from './calendar/calendar-reducer';
import appReducer from './app/app-reducer';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import thunk from 'redux-thunk';
import overviewReducer from './overview/overview-reducer';
import { StateType } from 'typesafe-actions';

const rootReducer = combineReducers({
  app: appReducer,
  entities: entitiesReducer,
  overview: overviewReducer,
  calendar: calendarReducer,
});

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(epicMiddleware, thunk)),
);

epicMiddleware.run(rootEpic as any);

export default store;

(window as any).store = store;

export type Store = typeof store;

export type RootState = StateType<typeof rootReducer>;
