import { createAction, ActionType } from 'typesafe-actions';
import { ActivityDocument } from '../../data/database/entities/activity';
import { DayActivityDocument } from '../../data/database/entities/day-activity';

export const receiveActivities = createAction('ACTIVITIES_RECEIVED', action => {
  return (activities: ActivityDocument[]) => action(activities);
});

export const receiveDayActivities = createAction(
  'DAY_ACTIVITIES_RECEIVED',
  action => {
    return (dayActivities: DayActivityDocument[]) => action(dayActivities);
  },
);

const actions = { receiveActivities, receiveDayActivities };

export type EntitiesAction = ActionType<typeof actions>;

export function changeActivityTitle(
  activityId: string,
  title: string,
): ThunkAction {
  return async (_dispatch, getState) => {
    const state = getState();

    const activity = await state.app.database.activities
      .findOne(activityId)
      .exec();

    if (activity) {
      activity.atomicSet('title', title);
    }
  };
}

export function toggleActivityArchive(activityId: string): ThunkAction {
  return async (_dispatch, getState) => {
    const state = getState();

    const activity = await state.app.database.activities
      .findOne(activityId)
      .exec();

    if (activity) {
      activity.atomicSet('archived', !activity.archived);
    }
  };
}
