import { RxDocument, RxCollection, RxJsonSchema } from 'rxdb';
import { ActivityId } from './activity';

export type DayActivityDocType = {
  type: 'day_activity';
  date: string;
  activityId: ActivityId;
};

export type DayActivityDocMethods = {};

export type DayActivityDocument = RxDocument<
  DayActivityDocType,
  DayActivityDocMethods
>;

export type DayActivityCollectionMethods = {};

export type DayActivityCollection = RxCollection<
  DayActivityDocType,
  DayActivityDocMethods,
  DayActivityCollectionMethods
>;

export const dayActivitySchema: RxJsonSchema = {
  title: 'day activity schema',
  version: 0,
  type: 'object',
  properties: {
    _id: {
      type: 'string',
      primary: true,
    },
    type: {
      type: 'string',
      index: true,
    },
    date: {
      type: 'string',
      format: 'string',
      index: true,
    },
    activityId: {
      ref: 'activities',
      type: 'string',
      index: true,
    },
  },
  required: ['date', 'activityId'],
};
