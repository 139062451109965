import { RxDocument, RxCollection, RxJsonSchema } from 'rxdb';

export type ActivityId = string;

export type ActivityDocType =
  | {
      _id: ActivityId;
      archived: boolean;
      type: 'activity';
      title: string;
      shortcode: string;
      hidden: boolean;
      temporary: false;
    }
  | {
      _id: ActivityId;
      archived: boolean;
      type: 'activity';
      title: string;
      shortcode: string;
      hidden: boolean;
      temporary: true;
      begin: DateIndex;
      end: DateIndex;
    };

export type ActivityDocMethods = {};

export type ActivityDocument = RxDocument<ActivityDocType, ActivityDocMethods>;

export type ActivityCollectionMethods = {};

export type ActivityCollection = RxCollection<
  ActivityDocType,
  ActivityDocMethods,
  ActivityCollectionMethods
>;

export const activitySchema: RxJsonSchema = {
  title: 'activity schema',
  version: 1,
  type: 'object',
  properties: {
    _id: {
      type: 'string',
      primary: true,
    },
    archived: {
      type: 'boolean',
    },
    type: {
      type: 'string',
      index: true,
    },
    title: {
      type: 'string',
    },
    shortcode: {
      type: 'string',
    },
    temporary: {
      type: 'boolean',
    },
    hidden: {
      type: 'boolean',
    },
    begin: {
      type: 'string',
      format: 'date',
    },
    end: {
      type: 'string',
      format: 'date',
    },
  },
  required: ['type', 'title', 'shortcode', 'temporary', 'hidden'],
};

export const activityMigrationStrategies = {
  1: (oldDoc: ActivityDocument) => {
    oldDoc.archived = false;
    return oldDoc;
  },
};
