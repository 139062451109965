import { useEffect, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

function useLockBodyScroll(toggle: boolean) {
  const [elementRef, setElementRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef && toggle) {
      disableBodyScroll(elementRef);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [elementRef, toggle]);

  return setElementRef;
}

export default useLockBodyScroll;
