import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRoutes, HookRouter } from 'hookrouter';
import Layout from './layouts/main';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

const Activities = React.lazy(() => import('./pages/activities'));
const ActivityNew = React.lazy(() => import('./pages/activities/new'));
const ActivityShow = React.lazy(() => import('./pages/activities/show'));
const Calendar = React.lazy(() => import('./pages/calendar'));
const Login = React.lazy(() => import('./pages/login'));
const Logout = React.lazy(() => import('./pages/logout'));
const Overview = React.lazy(() => import('./pages/overview'));
const Register = React.lazy(() => import('./pages/register'));
const User = React.lazy(() => import('./pages/user'));

const routes = {
  '/': () => (
    <Layout>
      <Overview />
    </Layout>
  ),
  '/activities': () => (
    <Layout>
      <Activities />
    </Layout>
  ),
  '/calendar': () => (
    <Layout>
      <Calendar />
    </Layout>
  ),
  '/activities/add': () => (
    <Layout>
      <ActivityNew />
    </Layout>
  ),
  '/activities/:id': ({ id }: HookRouter.QueryParams) => (
    <Layout padding={false}>
      <ActivityShow id={id} />
    </Layout>
  ),
  '/register': () => (
    <Layout>
      <Register />
    </Layout>
  ),
  '/login': () => (
    <Layout>
      <Login />
    </Layout>
  ),
  '/logout': () => (
    <Layout>
      <Logout />
    </Layout>
  ),
  '/user': () => (
    <Layout>
      <User />
    </Layout>
  ),
};

function NotFound() {
  return <div>Not found</div>;
}

interface AppProps {
  store: Store;
}

const GlobalStyle = createGlobalStyle`
body {
  background: #1a202c;
  color:white;
}
`;

function App({ store }: AppProps) {
  const match = useRoutes(routes) || <NotFound />;

  return (
    <Provider store={store}>
      <GlobalStyle />
      <ToastContainer
        className="mt-4 w-64 md:w-auto"
        style={{ left: '50%', transform: 'translateX(-50%)', marginLeft: 0 }}
        position="top-center"
        hideProgressBar
        transition={Slide}
      />
      {match}
    </Provider>
  );
}

export default App;
